<div style="padding: 0 0.5rem; width: 50%;  margin: auto; margin-top: 3rem;">
    <div class="queryForm" style="padding: 0.6rem 0.6rem 0; width: 100%; display: block;">
        <div class="queryFormField queryFormField--entitySelector">
            <mat-form-field appearance="fill"
                class="mat-datepicker-toggle queryFormField queryFormField--dateTemplateSelector"
                style="width: 40rem;padding-bottom: 0">
                <mat-label>{{'Billing.selectYear' | translate}} </mat-label>
                <mat-select panelClass="templateSelectorPanel" [(ngModel)]="selectedYear">
                    <mat-option [value]="year" *ngFor="let year of years">
                        {{ year }}
                    </mat-option>
                </mat-select>
                <mat-icon matPrefix style="margin-right: 15px">event_note</mat-icon>
            </mat-form-field>
        </div>

        <div class="flex flex-row gap-[2rem]">
            <div class="queryFormField queryFormField">
                <mat-form-field appearance="fill"
                    class="mat-datepicker-toggle queryFormField queryFormField--dateTemplateSelector"
                    style="width: 19rem; padding-bottom: 0">
                    <mat-label>{{'Billing.selectQuarter' | translate}} </mat-label>
                    <mat-select panelClass="templateSelectorPanel" [(ngModel)]="selectedQuarter">
                        <mat-option [value]="quarter" *ngFor="let quarter of quarters">
                            {{ quartersName[quarter-1] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="queryFormField queryFormField">
                <mat-form-field appearance="fill"
                    class="mat-datepicker-toggle queryFormField queryFormField--dateTemplateSelector"
                    style="width: 19rem; padding-bottom: 0">
                    <mat-label>{{'Billing.selectMonth' | translate}} </mat-label>
                    <mat-select panelClass="templateSelectorPanel" [(ngModel)]="selectedMonth">
                        <mat-option [value]="month" *ngFor="let month of months">
                            {{ monthsName[month-1] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-row gap-[2rem]">
            <button id="queryButton" mat-button style="width: 19rem; font-size: large;" class="queryFormButton"
                type="button" [ngClass]="queryButtonUnweighted ? 'unweighted' : ''"
                [disabled]="((progressBarService.needed | async) && (progressBarService.mode | async) === 'query')"
                (click)="buttonClick()">
                {{'Download Quarterly' | translate}}
            </button>
            <button id="queryButton" mat-button style="width: 19rem; font-size: large;" class="queryFormButton"
                type="button" [ngClass]="queryButtonUnweighted ? 'unweighted' : ''"
                [disabled]="((progressBarService.needed | async) && (progressBarService.mode | async) === 'query')"
                (click)="buttonClickMonthly()">
                {{'Download Monthly' | translate}}
            </button>
        </div>

    </div>
    <ng-container [ngTemplateOutlet]="queryLoadingTemplate"></ng-container>
    <div *ngIf="noDataFound">
        <span>{{'Billing.NoDataFound' | translate}}</span>
    </div>
</div>

<ng-template #queryLoadingTemplate>
    <div *ngIf="(progressBarService.needed | async) && (progressBarService.mode | async) === 'query'"
        class="query-loading-container">
        <h4 style="font-size: 1rem">
            {{ 'Report.General.QueryInProgress' | translate }}...
        </h4>
        <button mat-stroked-button color="primary" (click)="abortQuery()">
            {{ 'Report.General.Abort' | translate }}
        </button>
    </div>
</ng-template>